import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import "@fontsource/poppins";
import { theme } from "../lib/theme";

const GlobalStyle = createGlobalStyle`
  html {
    font-size: .625em;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: ${theme.color.lightGray[100]};
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
  }

  * {
    box-sizing: border-box;
  }

  button {
    font-family: 'Poppins';
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .fadeIn {
    animation-name: fadeIn;
  }
`;

const StyledLayout = styled.div`
  overflow: visible;
  min-height: 100vh;
`;

export default function Layout({ children }) {
  return (
    <StyledLayout id="layout">
      <GlobalStyle />
      {children}
    </StyledLayout>
  );
}
