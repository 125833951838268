import styled from "styled-components";
import { theme } from "./theme";

export const Button = styled.button<{ secondary?: boolean }>`
  transition: 333ms ease all;
  background: ${(p) =>
    p.secondary ? theme.color.white[100] : theme.color.blue[100]};
  height: 4.8rem;
  line-height: 4.4rem;
  padding: 0 2.4rem;
  border-radius: 0.8rem;
  border: 0;
  color: ${(p) =>
    p.secondary ? theme.color.dark[100] : theme.color.white[100]};
  font-weight: 600;
  font-size: 1.6rem;
  letter-spacing: 0.01rem;
  cursor: pointer;
  outline: 0;
  border: 2px solid
    ${(p) => (p.secondary ? theme.color.white[100] : theme.color.blue[100])};

  &:hover {
    background: ${(p) =>
      p.secondary ? theme.color.dark[90] : theme.color.blue[130]};
    color: ${(p) => p.secondary && theme.color.white[100]};
    box-shadow: 0px 4px 20px rgba(3, 3, 1, 0.06);
    border: 2px solid
      ${(p) => (p.secondary ? theme.color.white[100] : theme.color.blue[130])};
  }

  &:disabled {
    border: 2px solid ${theme.color.blue[30]};
    background: ${theme.color.blue[30]};
  }
`;
