import styled, { css } from "styled-components";
import { theme } from "./theme";

export enum SIZES {
  "S" = "small",
  "M" = "medium",
}

const getParagraphStyles = (size: string) => {
  switch (size) {
    case SIZES.S:
      return css`
        font-size: 1.3rem;
        line-height: 1.8rem;
      `;

    case SIZES.M:
      return css`
        font-size: 1.6rem;
        line-height: 2.4rem;
      `;

    default:
      break;
  }
};

const Paragraph = styled.p<{ size: string }>`
  font-weight: 500;
  color: ${theme.color.dark[100]};
  ${(p) => getParagraphStyles(p.size)};
`;

export default Paragraph;
