import styled, { css } from "styled-components";
import { theme } from "./theme";

// TODO: add different levels for headings

export enum HEADINGS {
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  H4 = "h4",
  H5 = "h5",
  H6 = "h6",
}

const getHeadingStyles = (size: string) => {
  switch (size) {
    case HEADINGS.H1:
      return css`
        font-size: 4rem;
        line-height: 4.8rem;
      `;

    case HEADINGS.H2:
      return css`
        font-size: 3.2rem;
        line-height: 3.6rem;
      `;

    case HEADINGS.H3:
      return css`
        font-size: 2.4rem;
        line-height: 3.6rem;
      `;

    case HEADINGS.H4:
      return css`
        font-size: 1.6rem;
        line-height: 2.4rem;
      `;

    case HEADINGS.H5:
      return css`
        font-size: 1.3rem;
        line-height: 1.8rem;
      `;

    default:
      break;
  }
};

const Title = styled.h3<{ size: string }>`
  font-weight: 600;
  color: ${theme.color.dark[100]};
  ${(p) => getHeadingStyles(p.size)};
`;

export default Title;
