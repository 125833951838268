import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 118rem;
  margin: 0 auto;

  @media (max-width: 32em) {
    padding: 0 2rem;
  }
`;
