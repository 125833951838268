export const theme = {
  color: {
    dark: {
      10: "#E6E6E7",
      20: "#CCCCD0",
      30: "#B3B3B8",
      50: "#4E4D5A",
      70: "#353342",
      90: "#1B1A2B",
      100: "#020013",
    },
    white: {
      100: "#FFFFFF",
      10: "rgba(255, 255, 255, 0.1)",
    },
    blue: {
      130: "#1400A5",
      100: "#1d00ec",
      30: "#BBB3F9",
      10: "#E6EDFF",
    },
    lightGray: {
      100: "#F5F5F5",
    },
    red: {
      100: "#F51309",
      900: "#FFCACA",
    },
    green: {
      100: "#018E42",
      10: "#E6F4EC",
    },
  },
};
